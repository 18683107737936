import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

//Components
import LoginButton from '../loginBtn';
import LogoutButton from '../logoutBtn';

const AuthenticationButton = () => {
    const { isAuthenticated } = useAuth0();
    return isAuthenticated ? <LogoutButton /> : <LoginButton />;
}

export default AuthenticationButton
