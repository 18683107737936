import { createGlobalStyle} from 'styled-components';

export const lightTheme = {
    body: 'linear-gradient(to bottom right, #f2f2f2 50%, #f5f5f5 50%)',
    text: '#000000',
    link: '#000000',
    button: '#191919',
    buttonText: '#ffffff',
    sectionLine: '#000000',
    toggleBorder: '#FFF',
    background: '#292929',
}
export const darkTheme = {
    body: 'linear-gradient(to bottom right, #292929 50%, #363636 50%)',
    text: '#ffffff',
    link: '#ffffff',
    button: '#f2f2f2',
    buttonText: '#000000',
    sectionLine: '#d6d6d6',
    toggleBorder: '#6B8096',
    background: '#999',
}

export const ThemeStyles = createGlobalStyle
`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.50s linear;
  }
  
  a {
    color: ${({ theme }) => theme.link};
  }
  
  button {
    background: ${({ theme }) => theme.button};
    color: ${({ theme }) => theme.buttonText};
  }
  
  section {
    background: ${({ theme }) => theme.sectionLine};
  }`