import * as MdIcons from 'react-icons/md';

export const NavbarData =[
    {
        title: 'Dashboard',
        path: '/',
        cName: 'navText',
        icon: <MdIcons.MdSpaceDashboard />,
    },
    // {
    //     title: 'Calendar',
    //     path: '/Calendar',
    //     cName: 'navText in-active',
    //     icon: <MdIcons.MdOutlineCalendarToday />,
    // },
    // {
    //     title: 'Reports',
    //     path: '/Reports',
    //     cName: 'navText in-active',
    //     icon: <MdIcons.MdOutlineFlag />,
    // },
    // {
    //     title: 'Projects',
    //     path: '/Projects',
    //     cName: 'navText',
    //     icon: <MdIcons.MdAllInbox />,
    // },
    // {
    //     title: 'HRM',
    //     path: '/HRM',
    //     cName: 'navText in-active',
    //     icon: <MdIcons.MdAccountCircle />,
    // },
]