import './styles.css'

const Loading = () => {
    return (
        <div className="load__body">

        <div className="load__animation">
        </div>

        <div className="load__text">
            <p></p>
        </div>
        </div>
    )
}

export default Loading