import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

//Styles
import './styles.css';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
      <button 
      className="loginBtn"
      onClick={() => loginWithRedirect()}>
        Log In
      </button>
  )
}

export default LoginButton