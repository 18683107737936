import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as CgIcons from 'react-icons/cg';
import FadeIn from 'react-fade-in/lib/FadeIn';

//Styles
import './styles.css'

//Assets
import { NavbarData } from '../../utils/NavbarData.jsx';

//Components
import AuthenticationButton from '../authentication-button';

//Dark Mode
import {ThemeProvider} from 'styled-components';
import { lightTheme, darkTheme, ThemeStyles } from '../../theme/Theme.jsx';

const Navbar = () => {
    //Menu Slide out
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    // Handle Dark Mode
    const [theme, setTheme] = useState('light');
    const themeToggler = () => {
      theme === 'light' ? setTheme('dark') : setTheme('light')
    }

    return (
        <>
            <FadeIn delay={1000}>
            <div className="navbar">
                <div className="navbar__left">
                    {/* <Logo /> */}
                </div>
                <div className="navbar__right">
                    <CgIcons.CgMenuGridO 
                    className="menu__btn"
                    onClick={showSidebar} />
                    {/* <div className="menu__btn" onClick={showSidebar}>
                        <p>Menu</p>
                        <div className="menu__btnUnderline"></div>
                    </div> */}
                </div>
            </div>

            <nav className={sidebar ? "navMenu active" : "navMenu"}>
            <div className="navMenu__items" onClick={showSidebar}>

                <div className="navMenu__buttons">
                <AuthenticationButton />
                <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
                <ThemeStyles/>
                <CgIcons.CgDarkMode 
                className="darkMode__btn"
                onClick={themeToggler} />
                </ThemeProvider>
                <CgIcons.CgClose
                className="menuBars__close"
                onClick={!showSidebar} />
                </div>
            <div className="navbar__top">
            {NavbarData.map((item, index) => {
            return (
                <div key={index} className="navItem">
                  <NavLink 
                  exact={true}
                  to={item.path} 
                  className={item.cName}
                  activeClassName="is-active">
                    <span>
                        {item.icon}
                    </span>
                    <p>
                    {item.title}
                    </p>
                  </NavLink>
                </div>
              )
            })}
            </div>
            </div>
            </nav>
            </FadeIn>
        </>
    )
}

export default Navbar
