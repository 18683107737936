import { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component'
import AnimatedCursor from 'react-animated-cursor';
import { useAuth0 } from '@auth0/auth0-react';

//Styles
import './theme/globals.css';

//Components
import Navbar from './components/navbar';

//Auth
import ProtectedRoute from './auth/protected-route';

//Pages
const Loading = loadable(() => import('./pages/loading'));
// const Profile = loadable(() => import('./pages/profile'));
// const Home = loadable(() => import('./pages/home'));
// const Cal = loadable(() => import('./pages/calander'));
const Client = loadable(() => import('./pages/clientDetails'));
const Projects = loadable(() => import('./pages/projects'));
const PageNotFound = loadable(() => import ('./pages/404'));

function App() {
  //Load Screen
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, []);

  //Authentication
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <Loading />;
  }

  //Smooth Scroll
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

  return (
    <>
    {loading === false ? (
    <div className="App">
      <AnimatedCursor
          innerSize={10}
          outerSize={8}
          color='245, 130, 32'
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5} />
          <Navbar />
          <Switch>
          <ProtectedRoute path="/Projects/:slug" component={Client} />
          <ProtectedRoute exact path="/" component={Projects} />
          {/* <ProtectedRoute path="/Calendar" component={Cal} /> */}
          {/* <ProtectedRoute path="/Projects" component={Projects} /> */}
          {/* <ProtectedRoute path="/Profile" component={Profile} /> */}
          <Route component={PageNotFound} />
        </Switch>
    </div>
    ) : (
      <Loading />
      )}
    </>
  );
}

export default App;
