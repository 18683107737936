import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as FiIcons from 'react-icons/fi';

//Style
import './styles.css';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
      <button 
      className="logOutBtn"
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }>
        <span><FiIcons.FiLogOut /></span> Logout
      </button>
  )
}

export default LogoutButton